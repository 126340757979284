/**
 * Stores build information. The string literals are replaces at compile time by `set_version.js`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2024-07-31",
    versionChannel: "nightly",
    buildDate: "2024-07-31T00:05:03.632Z",
    commitHash: "5806e6577e253ea198bdc4835ac867834bf33d11",
};
